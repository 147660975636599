<template>
  <div class="center">
    <h1>Notifications</h1>
    <p>État de l'autorisation de notifications : {{ notificationPermission }}</p>
    <button @click="notifyTest">Tester la notification</button>
  </div>
</template>

<script>
import { showNotification } from '@/utils/notification_utils'

export default {
  name: 'NotificationView',
  data () {
    return {
      notificationPermission: 'indéterminé'
    }
  },
  methods: {
    async notifyTest () {
      await showNotification('Test de notification', {
        body: 'test'
      })
    }
  },
  mounted () {
    this.notificationPermission = Notification.permission
  }
}
</script>

<style scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
