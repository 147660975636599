import { showNotification } from '@/utils/notification_utils'
import { useMainStore } from '@/store'

export async function syncPhotos () {
  // Récupérez les photos du localStorage
  const photos = JSON.parse(localStorage.getItem('photos')) || []
  let numberPhotosSynced = 0
  // Filtrer les photos qui ne sont pas synchronisées
  const unsyncedPhotos = photos.filter(photo => !photo.synchronized)

  // Parcourez chaque photo non synchronisée
  for (const photo of unsyncedPhotos) {
    // Marquez la photo comme synchronisée
    photo.synchronized = true
    numberPhotosSynced++
  }

  // Affichez une notification pour informer l'utilisateur du nombre de photos synchronisées
  if (numberPhotosSynced > 0) {
    await showNotification('Photos synchronisées', {
      body: `${numberPhotosSynced} photos ont été synchronisées.`
    })
  }

  // Mettez à jour le localStorage avec les photos synchronisées
  localStorage.setItem('photos', JSON.stringify(photos))

  // Mettez à jour le store pour indiquer que la synchronisation est terminée
  useMainStore().finishUpdatePictures(true)
}
