<template>
  <div class="center">
    <h1>Position</h1>
    <NotAvailable v-if="!onLine"></NotAvailable>
    <p v-if="latitude !== null && longitude !== null && onLine && !loading">Latitude : {{ latitude }}</p>
    <p v-if="latitude !== null && longitude !== null && onLine && !loading">Longitude : {{ longitude }}</p>
    <p v-if="loading">Chargement...</p>
  </div>
</template>

<script>
import { useMainStore } from '@/store'
import NotAvailable from '@/components/NotAvailable.vue'

export default {
  components: { NotAvailable },
  data () {
    return {
      latitude: null,
      longitude: null,
      loading: false
    }
  },
  computed: {
    onLine () {
      return useMainStore().onLine
    }
  },
  methods: {
    async getMyPosition () {
      if (!this.onLine) return
      if ('geolocation' in navigator) {
        this.loading = true
        await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.latitude = position.coords.latitude
              this.longitude = position.coords.longitude
              resolve()
            },
            (error) => {
              // Gérer les erreurs de géolocalisation
              console.error('Erreur de géolocalisation:', error)
              this.showNotification('Erreur de géolocalisation', 'Impossible de récupérer la position actuelle.')
              reject(error)
            }
          )
        }).catch(() => {}).finally(() => {
          this.loading = false
        })
      } else {
        // Gérer le cas où la géolocalisation n'est pas prise en charge par le navigateur
        await this.showNotification('Erreur de géolocalisation', "La géolocalisation n'est pas prise en charge par ce navigateur.")
        this.loading = false
      }
    }
  },
  watch: {
    onLine (newValue) {
      if (newValue) {
        this.getMyPosition()
      } else {
        this.latitude = null
        this.longitude = null
      }
    }
  },
  async mounted () {
    await this.getMyPosition()
  }
}
</script>

<style scoped>
.center {
  text-align: center;
}
</style>
