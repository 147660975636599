/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { syncPhotos } from '@/utils/photo_utils'

register(`${process.env.BASE_URL}service-worker.js`, {
  ready () {
    console.log(
      'App is being served from cache by a service worker.\n' +
      'For more details, visit https://goo.gl/AFskqB'
    )
  },
  async registered () {
    console.log('Service worker has been registered.')
    // Vérifiez l'état de la connexion lors de l'enregistrement du service worker
    if (navigator.onLine) {
      await syncPhotos()
    }

    // Ajoutez un écouteur d'événements pour l'événement 'online'
    window.addEventListener('online', syncPhotos)
  },
  cached () {
    console.log('Content has been cached for offline use.')
  },
  updatefound () {
    console.log('New content is downloading.')
  },
  updated () {
    console.log('New content is available; please refresh.')
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})
