<template>
  <div>
    <p>Cette fonctionnalitée n'est pas disponible hors ligne</p>
    <img src="@/assets/svg/wifi-ko.svg" alt="wifi-ko" class="svg"/>
  </div>
</template>

<script>
export default {
  name: 'NotAvailable'
}
</script>
<style scoped>
  img {
    width: 100px;
    height: 100px;
  }
</style>
