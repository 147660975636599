<template>
  <header class="header">
    <div class="title div1">PWA App</div>
    <div class="div2 links">
      <RouterLink to="/">Home</RouterLink>
      <RouterLink to="/camera">Camera</RouterLink>
      <RouterLink to="/position">Position</RouterLink>
      <RouterLink to="/notification">Notifications</RouterLink>
      <RouterLink to="/appel">Appel</RouterLink>
      <RouterLink to="/webotp">WebOTP</RouterLink>
    </div>
    <div class="div3 align-end">
      <div class="battery-level">{{ batteryLevel }}%</div>
      <BatteryLevel :batteryLevel="batteryLevel" :isCharging="isCharging" />
      <div v-if="onLine" class="online">
        <img src="@/assets/svg/wifi-ok.svg" alt="wifi-ok" class="svg" />
        <div class="green-point"></div>
      </div>
      <div v-else class="offline">
        <img src="@/assets/svg/wifi-ko.svg" alt="wifi-ko" class="svg"/>
        <div class="red-point"></div>
      </div><!--      <div v-if="showBackOnline" class="back-online">Back Online</div>-->
    </div>
  </header>
</template>

<script>
import { RouterLink } from 'vue-router'
import { useMainStore } from '@/store'
import BatteryLevel from '@/components/BatteryLevel.vue'

export default {
  name: 'Header',
  data () {
    return {
      batteryLevel: null,
      isCharging: false
    }
  },
  components: {
    BatteryLevel,
    RouterLink
  },
  computed: {
    onLine () {
      return useMainStore().onLine
    }
  },
  async mounted () {
    if ('getBattery' in navigator) {
      const battery = await navigator.getBattery()
      this.batteryLevel = Math.round(battery.level * 100)
      this.isCharging = battery.charging

      // Mettez à jour le niveau de la batterie lorsque celui-ci change
      battery.addEventListener('levelchange', () => {
        this.batteryLevel = Math.round(battery.level * 100)
      })

      // Mettez à jour l'état de charge lorsque celui-ci change
      battery.addEventListener('chargingchange', () => {
        this.isCharging = battery.charging
      })
    } else {
      // Handle the case where the Battery Status API is not supported
      console.log('Battery Status API is not supported by this browser.')
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  align-items: center;
  padding: 20px;
  height: 50px; /* Ajoutez la hauteur que vous voulez ici */
}

.links{
  display: flex;
  justify-content: space-around;
  a {
    color: #000;
    text-decoration: none;
  }
}

.align-end {
  justify-self: end;
}

.div3 {
  grid-area: 1 / 5 / 2 / 6;
  display: flex;
  padding-left: 20px;
}
.div2 {
  grid-area: 1 / 4 / 2 / 5
}
.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.svg {
  width: 20px;
  height: 20px;
}

.red-point {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-left: 10px;
}

.green-point {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-left: 10px;
}

.green-point, .red-point {
  margin-right: 10px;
}

.online, .offline {
  display: flex;  margin-left: 10px;

  align-items: center;
}
.links a {
  padding: 0 10px; /* Ajoutez le padding que vous voulez ici */
}

.battery-level {
  margin-right: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .header {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto repeat(4, 1fr) 0.2fr;
  }

  .div1, .div2 {
    grid-area: auto;
  }

  .div3 {
    grid-area: 1 / 2 / 2 / 3;
    justify-self: end;
  }

  .links {
    flex-direction: column;
    margin-top: 20px; /* Ajoutez l'espace que vous voulez ici */
  }

  .links a {
    padding: 2px 0; /* Ajoutez le padding que vous voulez ici */
  }
}
</style>
