<template>
  <div class="center">
    <h1>Appel</h1>
    <div class="dial-pad">
      <button @click="dial(0)">0</button>
      <button v-for="n in 9" :key="n" @click="dial(n)">{{ n }}</button>
    </div>
    <button @click="erase">Effacer</button>
    <button @click="eraseAll">Effacer tout</button>
    <button @click="call">Appeler</button>
    <p>Numéro composé : {{ phoneNumber }}</p>
  </div>
</template>

<script>
export default {
  name: 'AppelView',
  data () {
    return {
      phoneNumber: ''
    }
  },
  methods: {
    dial (number) {
      if (this.phoneNumber.length < 10) {
        this.phoneNumber += number
      }
    },
    erase () {
      this.phoneNumber = this.phoneNumber.slice(0, -1)
    },
    eraseAll () {
      this.phoneNumber = ''
    },
    call () {
      window.location.href = `tel:${this.phoneNumber}`
    }
  }
}
</script>

<style scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dial-pad {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}
.dial-pad button {
  width: 60px;
  height: 60px;
  margin: 5px;
  font-size: 2em;
}
</style>
