<template>
  <div>
    <p>PWA Bastien METAIS</p>
    <img class="qr" src="../assets/qrcode.png" alt="Vue logo" />
  </div>
</template>

<style scoped>
p {
  text-align: center;
  margin-top: 50px;
  font-size: 2em;
  font-weight: normal;
}

.qr {
  width: 200px;
  display: block;
  margin: 0 auto;
}
</style>
