<template>
  <div class="wrapper">
    <h1>Camera</h1>
    <div v-if="loadingCamera" class="loading">Chargement de la caméra...</div>
    <video
      class="video"
      :class="facingMode === 'user' ? 'front' : ''"
      ref="video"
    />
    <canvas style="display: none" ref="canva" />

    <div class="photo-button-container" v-if="!loadingCamera">
      <button class="button photo-button" @click="takePhoto">
        Prendre une photo
      </button>
    </div>
    <div class="photo-button-container" v-if="this.photos.length > 0">
      <button class="button photo-button" @click="deleteAllPhotos">
        Effacer toutes les photos
      </button>
    </div>

    <photos-gallery class="gallery" :photos="photos" @delete-photo="deletePhoto" />
  </div>
</template>

<script>
import PhotosGallery from '../components/PhotoGallery.vue'
import { showNotification } from '@/utils/notification_utils'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    PhotosGallery
  },
  data () {
    return {
      photos: [],
      mediaStream: null,
      videoDevices: [],
      facingMode: 'environment',
      loadingCamera: false
    }
  },
  methods: {
    async StartRecording (facingMode) {
      this.facingMode = facingMode
      const video = this.$refs.video
      this.mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: facingMode }
      })
      video.srcObject = this.mediaStream
      return await video.play()
    },

    async takePhoto () {
      const video = this.$refs.video
      const canva = this.$refs.canva
      const width = video.videoWidth
      const height = video.videoHeight
      canva.width = width
      canva.height = height
      const ctx = canva.getContext('2d')
      ctx.save()

      if (this.facingMode === 'user') {
        ctx.scale(-1, 1)
        ctx.drawImage(video, width * -1, 0, width, height)
      } else {
        ctx.drawImage(video, 0, 0)
      }

      ctx.restore()

      this.photos.push({
        id: uuidv4(),
        src: canva.toDataURL('image/jpeg', 0.5),
        synchronized: navigator.onLine // Ajoutez cette ligne
      })

      // Ajoutez la photo au localStorage
      localStorage.setItem('photos', JSON.stringify(this.photos))

      if (!navigator.onLine) {
        await showNotification('Photo enregistrée', {
          body: 'La photo sera synchronisée lorsque vous serez en ligne.'
        })
      }
    },

    deletePhoto (id) {
      this.photos = this.photos.filter(photo => photo.id !== id)

      // Supprimez la photo du localStorage
      localStorage.setItem('photos', JSON.stringify(this.photos))
    },

    deleteAllPhotos () {
      this.photos = []
      localStorage.removeItem('photos')
    },

    updatePhotosFromLocalStorage (event) {
      if (event.key === 'photos') {
        console.log(event.newValue)
        this.photos = JSON.parse(event.newValue)
      }
    }
  },

  async mounted () {
    this.loadingCamera = true
    const devices = await navigator.mediaDevices.enumerateDevices()
    this.videoDevices = devices.filter((d) => d.kind === 'videoinput')
    await this.StartRecording(
      this.videoDevices.length === 1 ? 'user' : 'environment'
    )
    this.loadingCamera = false
  },
  created () {
    const photos = localStorage.getItem('photos')
    if (photos) {
      this.photos = JSON.parse(photos)
    }
    window.addEventListener('storage', this.updatePhotosFromLocalStorage)
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photo-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
