import { createPinia, defineStore } from 'pinia'

export const useStore = createPinia()

export const useMainStore = defineStore('main', {
  state: () => ({
    onLine: navigator.onLine,
    isFinishUpdate: false
  }),
  actions: {
    updateOnlineStatus (e) {
      const { type } = e
      this.onLine = type === 'online'
    },
    finishUpdatePictures (val) {
      this.isFinishUpdate = val
    }
  }
})
