<template>
  <div class="photo" :class="{ 'synchronized': synchronized }">
    <img :src="photo.src" alt="image" />
    <button class="delete-button" @click="deletePhoto">
      <img src="@/assets/svg/remove.svg" alt="delete" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    photo: {
      type: Object,
      default: null
    },
    synchronized: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    deletePhoto () {
      this.$emit('delete', this.photo.id)
    }
  }
}
</script>

<style scoped>
.photo {
  position: relative;
  display: inline-block;
  border: 2px solid red;
  width: 200px; /* Fixed width */
  height: 200px; /* Fixed height */
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.delete-button img {
  width: 20px;
}

.synchronized {
  border-color: green;
}
</style>
