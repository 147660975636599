<template>
  <div class="photos-container">
    <photo
      class="list-animated-item"
      v-for="photo in photos"
      :photo="photo"
      :synchronized="photo.synchronized"
      :key="photo.id"
      @delete="emitDeleteEvent(photo.id)"
    />
  </div>
</template>

<script>
import Photo from './Photo.vue'
export default {
  components: { Photo },
  props: {
    photos: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    emitDeleteEvent (id) {
      this.$emit('delete-photo', id)
    }
  }
}
</script>

<style scoped>

.photos-container {
  display: flex;
  gap: 10px;
  padding: 10px;
  width: 80%;
  overflow-x: auto;
}
</style>
