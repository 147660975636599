export async function showNotification (title, payload) {
  const registration = await navigator.serviceWorker.getRegistration()

  if (Notification.permission === 'granted') {
    // eslint-disable-next-line no-new
    if ('showNotification' in registration) {
      await registration.showNotification(title, payload)
    } else {
      // eslint-disable-next-line no-new
      new Notification(title, payload)
    }
    if ('vibrate' in navigator) {
      navigator.vibrate(200)
    } else {
      alert('Notification autorisée mais pas de vibration')
    }
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        // Afficher une notification si la permission est accordée
        // eslint-disable-next-line no-new
        new Notification('Notifications', {
          body: 'La notifications a été envoyé avec succès.'
        })
        if ('vibrate' in navigator) {
          navigator.vibrate(200)
        } else {
          alert('Notification autorisée mais pas de vibration')
        }
      }
    })
  }
}
