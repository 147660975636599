<template>
  <div class="parent">
    <Header class="header"></Header>
    <RouterView class="body"></RouterView>
  </div>
</template>

<script>
import { RouterView } from 'vue-router'
import Header from '@/components/Header.vue'
import { useMainStore } from '@/store'

export default {
  name: 'App',
  components: {
    RouterView,
    Header
  },
  mounted () {
    window.addEventListener('online', this.updatePiniaStore)
    window.addEventListener('offline', this.updatePiniaStore)
  },
  beforeUnmount () {
    window.removeEventListener('online', this.updatePiniaStore)
    window.removeEventListener('offline', this.updatePiniaStore)
  },
  methods: {
    updatePiniaStore (e) {
      const store = useMainStore()
      store.updateOnlineStatus(e)
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.parent {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 40px;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.body { grid-area: 2 / 1 / 6 / 6; margin-top: 100px; }
.header { grid-area: 1 / 1 / 2 / 6; }
</style>
