<template>
  <svg width="50" height="20" viewBox="0 0 50 20">
    <rect x="0" y="0" width="45" height="20" stroke="black" fill="none" />
    <rect x="45" y="5" width="5" height="10" stroke="black" fill="none" />
    <rect
      x="2"
      y="2"
      :width="(batteryLevel / 100) * 41"
      height="16"
      fill="green"
      v-if="batteryLevel > 20"
    />
    <rect
      x="2"
      y="2"
      :width="(batteryLevel / 100) * 41"
      height="16"
      fill="yellow"
      v-else-if="batteryLevel > 10"
    />
    <rect
      x="2"
      y="2"
      :width="(batteryLevel / 100) * 41"
      height="16"
      fill="red"
      v-else
    />
    <!-- Icône "éclair" qui s'affiche lorsque isCharging est true -->
    <image
      v-if="isCharging"
      href="@/assets/svg/eclair.svg"
      x="15"
      y="2"
      height="16"
      width="20"
    />
  </svg>
</template>

<script>
export default {
  props: {
    batteryLevel: {
      type: Number,
      required: true
    },
    isCharging: {
      type: Boolean,
      required: true
    }
  }
}
</script>
