import { createMemoryHistory, createRouter } from 'vue-router'

import HomeView from './views/Home.vue'
import CameraView from './views/Camera.vue'
import PositionView from './views/Position.vue'
import NotificationView from './views/Notification.vue'
import AppelView from './views/Appel.vue'
import WebOTPView from './views/WebOTP.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/camera', component: CameraView },
  { path: '/position', component: PositionView },
  { path: '/notification', component: NotificationView },
  { path: '/appel', component: AppelView },
  { path: '/webotp', component: WebOTPView }
]

const router = createRouter({
  history: createMemoryHistory(),
  routes
})

export default router
