<template>
  <div>
    <input type="text" v-model="otp" placeholder="Enter OTP">
    <button @click="requestOTP">Request OTP</button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      otp: ''
    }
  },
  methods: {
    requestOTP () {
      if ('OTPCredential' in window) {
        navigator.credentials.get({
          otp: { transport: ['sms'] },
          signal: this.otp
        }).then(otp => {
          this.otp = otp.code
        }).catch(err => {
          console.error(err)
        })
      }
    }
  }
}
</script>
